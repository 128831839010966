import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3517265547/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3517265547/src/web-app/packages/queries/dist/src/users/mutations/useUserUpload.js");
;
import(/* webpackMode: "eager", webpackExports: ["ModuleClientLayout"] */ "/codebuild/output/src3517265547/src/web-app/packages/webapp/app/cw/[subdomain]/(app)/questboard/[moduleId]/_layouts/ModuleClientLayout.tsx");
